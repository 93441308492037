
.player {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.player_summary {
    display: flex;
    flex-direction: column;
}

.player_details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lover {
    display: flex;
    align-items: center;
}

.killer {
    display: flex;
    align-items: center;
}