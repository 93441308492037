.player {
    margin: 1em;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.games {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.search {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.player_details {}

.player_title {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
}

.games_title {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
}


.alert_player {
    margin-top: 1em;
}