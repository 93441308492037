
.event {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.5em;
    padding: 0.5em;
    box-shadow: 0.01em 0.01em 0.01em rgb(70, 69, 69);
    border-radius: 0.2em;
    width: 100%;
}

.timer {
    margin-right: 0.5em;
    font-weight: bold;
}

.text {}