.card{
    padding: 1em;
    margin: 1em;
    border-radius: 5%;
    min-width: 275;
    border: '1em';
}

.disabled{
    visibility: hidden;
}