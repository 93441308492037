.players {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.players_title {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
}

.player {
    margin: 0.5em;
}

.pagination {
    margin-bottom: 1em;
}