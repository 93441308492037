.footer {
    width: 100%;
    height: 10px;
}

.elements {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer_title {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1em;
    color: rgb(153, 152, 152);
}

.dev{
    color: rgb(153, 152, 152);
}