.games {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.games_title {
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}