.events {
    width: 100%;
}

.game_title {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.pagination {
    margin-bottom: 1em;
}