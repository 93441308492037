body {
  font-family: 'eesti';
  font-size: 16px;
  background: #ffffff;
  font-family: 'Fanwood Text', serif;

}

h1, h2, h3, h4 {
  font-family: 'Tenor Sans', sans-serif;
}
