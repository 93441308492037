.head {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
}

.player_name {
    margin-left: 0.5em;
    margin-right: 0.5em;

}

.player_info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.player_name:link {
    color: rgba(190, 30, 118, 0.884);
}

.player_name:visited {
    color: rgba(204, 70, 17, 0.884);
}