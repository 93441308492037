.players{
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
}

.title{
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em;
}